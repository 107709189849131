<script setup lang="ts">
import { ref, watchEffect } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    label?: string
    modelValue?: string
    error?: boolean
    errors?: string[]
    minHeight?: number
  }>(),
  {
    minHeight: 180,
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', val: any): void
}>()

const modelValue = ref(props.modelValue)

watchEffect(() => {
  modelValue.value = props.modelValue
})

const resize = () => {
  if (!textareaRef.value) return

  textareaRef.value.style.height = 'auto'
  textareaRef.value.style.height = `${Math.max(textareaRef.value.scrollHeight, props.minHeight)}px`
}

const updateModelValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
  resize()
}

const textareaRows = ref(1)
const textareaRef = ref<HTMLElement>()

onMounted(() => {
  nextTick(() => {
    resize()
  })
})
</script>

<template>
  <div>
    <label class="relative flex w-full flex-col gap-1">
      <slot name="label">
        <span v-if="label" class="text-black">{{ label }}</span>
      </slot>
      <textarea
        ref="textareaRef"
        v-bind="$attrs"
        v-model="modelValue"
        class="resize-none overflow-hidden overscroll-none rounded-xl border p-4 transition-all duration-300 hover:border-black focus:border-primary focus:outline-none active:border-primary"
        :class="[error ? 'border-error' : 'border-anti-flash-white']"
        :rows="textareaRows"
        @input="updateModelValue"
      />
    </label>
    <Transition
      enter-active-class="animate__animated animate__faster animate__fadeIn"
      leave-active-class="animate__animated animate__faster animate__fadeOut"
      mode="out-in"
    >
      <div v-if="errors?.length && error" class="flex flex-col gap-1 p-1 text-mobile text-red-500">
        <span v-for="item in errors" :key="item">
          {{ item }}
        </span>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss"></style>
